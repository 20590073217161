export default [
  {
    order: 1,
    name: "Internal (highest priority)",
    value: "Internal",
  },
  {
    order: 2,
    name: "Final",
    value: "Final",
  },
  {
    order: 3,
    name: "Accepted Offer",
    value: "Accepted Offer",
  },
  {
    order: 4,
    name: "Offer",
    value: "Offer",
  },
  {
    order: 5,
    name: "Guidance (lowest priority)",
    value: "Guidance",
  },
];
